<template>
  <footer>
    <div class="container">
      <div class="row justify-content-center">
          <div class=" mt-4 mb-4 col-6 text-center">
          Copyright © {{ currentYear }}
          <a href="http://evenly.com.au/" target="_blank">Evenly</a>. All rights
          reserved.
          &nbsp;&nbsp; - &nbsp;&nbsp;
        <i class="ti-direction-alt mr-1"></i>&nbsp;&nbsp;
          <a href="https://evenly.com.au/supplieriq/help" target="_blank"
            >SupplierIQ Help</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>


<script>
export default {
  name: "Footer",

  data: function () {
    return {
      isLoading: false,
    };
  },
  methods: {
    async refresh_xero() {
      this.isLoading = true;
      await this.$store.dispatch("resync_and_reload_contacts_report");
      this.isLoading = false;
    },
    async reload_contacts_report() {
      await this.$store.dispatch("reload_contacts_report");
    },
  },
  computed: {
    currentYear() {
      var currentDate = new Date();
      return currentDate.getFullYear();
    },
    finished_syncing() {
      return this.$store.getters.finished_syncing;
    },
    freshReportAvailable() {
      if (!this.$store.state.contacts_report.report_id) {
        return false;
      }
      if (!this.$store.state.authed_xero.current_report_id) {
        return false;
      }
      return (
        this.$store.state.authed_xero.current_report_id !=
        this.$store.state.contacts_report.report_id
      );
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 991px) {
  .small-footer-nav {
    display: none;
  }
}
.small-footer-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #4563b5;
  width: calc(100% - 121px);
  margin: 0 5% 22px 5%;
  border-radius: 10px;
  -webkit-box-shadow: 0 24px 14px -22px rgba(0, 0, 0, 0.7);
  box-shadow: 0 24px 14px -22px rgba(0, 0, 0, 0.7);
}

ul.footer-nav {
  width: 100%;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  padding-left: 0;
  padding-top: 15px;
}
ul.footer-nav li {
  display: inline;
}
ul.footer-nav li a {
  text-align: center;
  width: 100%;
  padding: 1rem;
  color: #ffffff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
ul.footer-nav li a.router-link-active {
  color: #333;
  background: #ffffff;
  border-radius: 10px;
  text-decoration: none;
}
</style>
