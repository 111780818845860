<template>
  <div v-if="showSignUpForm">
    <template v-if="!signed_up">
      <p>
        <label>Name:</label>
        <input v-model="name" name="name" type="text" class="form-control">
      </p>
      <p class="error_msg" v-if="name_error">{{ name_error }}</p>
      <p>
        <label>Email:</label>
        <input v-model="email" name="email" type="email" class="form-control">
      </p>
      <p class="error_msg" v-if="email_error">{{ email_error }}</p>

      <p>
        <label>Password:</label>
        <input v-model="password" name="password" type="password" class="form-control">
      </p>
      <p class="error_msg" v-if="password_length_check">
        Password must be at least 12 characters
      </p>
      <p class="error_msg" v-else-if="password_error">{{ password_error }}</p>

      <p>
        <label>Repeat Password:</label>
        <input v-model="password2" name="repeat_password" type="password" class="form-control">
      </p>
      <p class="error_msg" v-if="password2_error">{{ password2_error }}</p>

      <p class="error_msg" v-if="otherError">{{ otherError }}</p>

      <button
        class="btn btn-primary btn-block mb-4"
        @click="check_signup_inputs"
        :disabled="loading"
      >
        <span class="ml-3">
          <strong>{{ button_text }}</strong>
        </span>
      </button>

      <a href="#" @click="exit">
        <p>Use a different sign up method</p>
      </a>

      <p>
        Your name and email matter, make sure we know who you are and how we can alert you of any supplier issues that arise.
      </p>
    </template>
    <template v-else>
      <div >
        For security, please verify your email address by clicking on the 'Send Verification Code' button
        below then entering the code you receive by email in the box below.
      </div>
      <div class="my-5">
        <button
          @click="send_email_otp"
          :loading="otp_sending"
          class="btn btn-sm col-6"
          :class="{
            'btn-danger' : countDownTimerForOTP <= 0,
            'btn-gray' : countDownTimerForOTP > 0
          }"
          >
          {{ otp_sent ?  `Resend code ${(countDownTimerForOTP > 0 ? `(${countDownTimerForOTP})` : '')}` : 'Send Verification Code' }}
        </button>
        
        <p v-if="otp_sent && !otp_error" class="alert alert-success mt-2">
          <strong>Sent.</strong> Check your inbox.
        </p>
        <p class="error_msg mt-2" v-if="otp_error">
          {{ otp_error }}
        </p>
      </div>

      <div class="row g-0 align-items-end mb-3">
        <label>Enter Verification Code from your email then click 'Continue':</label>
        <div class="col">
          <input v-model="otp" name="otp" type="text" class="form-control">
        </div>
      </div>

      <button
        class="btn btn-block"
        :class="{
          'btn-primary' : otp_sent,
          'btn-gray' : !otp_sent
        }"
        @click="login"
        :disabled="login_loading"
      >
        <span class="ml-3">
          <strong>Continue</strong>
        </span>
      </button>
    </template>

  </div>
  <business-details v-else-if="showBusinessDetails"/>
</template>
<script>
import api from "@/api.js";
import BusinessDetails from './BusinessDetails.vue';
import OTPMixins from "@/mixins/otp.js";

export default {
  name: "emailLogin",
  components: {
    BusinessDetails
  },
  mixins: [OTPMixins],
  data() {
    return {
      showSignUpForm: true,
      showBusinessDetails: false,
      loading: false,
      name: "",
      name_error: null,
      email: "",
      email_error: null,
      password: "",
      password_error: null,
      password2: "",
      password2_error: null,
      otherError: null,
      signed_up: false,
      login_loading: false,
    };
  },
  computed: {
    button_text(){
      if (this.loading) {
        return "Loading..."
      }
      return "Sign Up"
    },
    password_length_check() {
      return (this.password.length > 0 && this.password.length < 12)
    }
  },
  methods: {
    check_signup_inputs() {
      const hasError = false;

      // Clear error messages
      this.email_error = null;
      this.name_error = null;
      this.password_error = null;
      this.password2_error = null;

      // // Check inputs
      if (this.name == "") {
        this.name_error = "This field is required";
        hasError = true;
      }

      if (this.email == ""){
        this.email_error = "This field is required"
        hasError = true;
      }

      if (this.password == "") {
        this.password_error = "This field is required"
        hasError = true;
      } else if (this.password.length < 12) {
        this.password_error = "Password must be at least 12 characters"
        hasError = true;
      } else if (this.password2 != this.password) {
        this.password2_error = "Passwords must match"
        hasError = true;
      }

      if (!hasError) {
        this.signup()
      }
    },
    signup() {
      // Make signup api request
      this.loading = true
      window.dataLayer.push({
          event: 'app-action',
          app_action: 'signup',
          app_action_category: 'signup',
          app_action_label: 'password',
      })

      api.post('@@signup', {email: this.email, password: this.password, name: this.name, newsletter_option: false})
      .then(resp => {
        this.signed_up = true; // proceed to login (email verification)
      })
      .catch(error => {
        this.showSignUpForm = true;
        this.showBusinessDetails = false;

        // Communicate reason sign up failed
        if (error.response.data.message == "Missing email field") {
          this.email_error = "This field is required"
        } else if (error.response.data.message == "Invalid email") {
          this.email_error = "Please enter a valid email"
        } else if (error.response.data.message == "Email already in use") {
          this.email_error = "Email already in use"
        } else if (error.response.data.message) {
          // Remaining errors are password complexity errors
          this.otherError = error.response.data.message
        } else {
          // In case of 500 error/network issue
          this.password_error = "Sign up failed"
        }
      })
      .then(() => this.loading = false)
    },
    exit() {
      this.$emit("exit")
    },
    async login() {
      // Attempt to login
      this.otp_sent = false
      this.login_loading = true
      this.otp_error = null
      let response = null
      let error = null
      try {
        response = await api.post(
          '@@login',
          {otp: this.otp},
          {headers: {
            'Authorization': "Basic " + btoa(this.email + ":" + this.password)
          }}
        )
      } catch(err){
        error = err;
        if (error.response) {
          response = error.response;
        }
      }

      // If the response is successful then reload the page
      if (response !== null) {
        if (response.status === 200) {
          window.location.reload()
          return
        }
      }

      this.login_loading = false;

      // Process result
      if (response !== null) {
        if (response.status === 403) {
          this.countDownTimerForOTP = 0;
          this.otp_error = "Incorrect Code"
        } else {
          this.otp_error = "Unexpected error";
        }
      } else {
        this.otp_error = error | "Unexpected error"
      }
    },
  },
};
</script>
<style type="text/css" scoped>
.error_msg {
  color: #000;
  background-color: #FFBABA;
  border: 1px solid;
  padding: 15px;
}
</style>
