<template>
  <div>
    <div
      class="app-main"
      
    >
      <slot></slot>
    </div>
    
  </div>
</template>

<script>

export default {
  name: "appmain",

  methods:{
    
  }
}
</script>

