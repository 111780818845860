<template>
  <div>
    <h4>Reset Password</h4>
    <p>
      <label>Email:</label>
      <input v-model="email" name="email" type="email" class="form-control"/>
    </p>
    <p class="error_msg" v-if="email_error">{{ email_error }}</p>

    <p class="error_msg" v-if="otp_error">
      {{ otp_error }}
    </p>
    <p class="success_msg" v-if="reset_message">
      {{ reset_message }}
    </p>
    <button
      class="btn btn-block mb-2 btn-primary w-100"
      @click="reset"
      :disabled="loading"
    >
      <span class="ml-3">
        <strong>Send me a Reset Link</strong>
      </span>
    </button>
  </div>
</template>
<script>
import api from "@/api.js";

export default {
  name: "forgotPassword",
  data() {
    return {
      loading: false,
      email: "",
      email_error: null,
      reset_message: null,
      reset_error: null,
    };
  },
  computed: {
  },
  methods: {
    reset() {
      // Reset error messages
      this.email_error = null

      // Check email
      if (this.email == ""){
        this.email_error = "Please enter your email to reset your password"
        return
      }

      // Attempt to reset password
      this.loading = true
      api.post('@@reset', {email: this.email})
      .then(resp => {
        this.otp_error = null
        this.reset_message = "Check your inbox to complete password reset."
      })
      .catch(error => {
        this.show_signup_link = true
        this.reset_message = null
        this.otp_error = "We can't find an account with that email address. Please Sign Up" // link appears after
      })
      .then(() => this.loading = false)
    },
  },
};
</script>
<style type="text/css" scoped>
.error_msg {
  color: #000;
  background-color: #FFBABA;
  border: 1px solid;
  padding: 15px;
}
.success_msg {
  color: #000;
  background-color: #caf09d;
  border: 1px solid;
  padding: 15px;
}
</style>
