<template>
  <BusinessDetails v-if="showBusinessDetails"/>
  <div v-else >
    <div class="d-grid gap-2" id="org-selector">
      <h2>Select an Organisation</h2>

      <div v-for="org in connected_accounts" :key="org.account.account_id" class="d-grid gap-2">
        <button class="btn btn-secondary organisations" style="height: 50px;" @click="setSelectedOrg(org.account.account_id)">
          {{ org.account.display_name }}
        </button>
      </div>
      <button
        class="btn btn-primary"
        @click="connectNewAccount()"
      >
        <span class="ml-3">
          Add New Account
        </span>
      </button>
    </div>



    <!-- <div v-else  class="d-grid gap-2">
      <h2>Connect a Xero Organisation</h2>
      <p>Sign in with Xero to connect an organisation to your account.</p>
      <button
        class="btn btn-primary"
        @click="goToXeroLogin()"
        id="btnConnectWithXeroIdle"
      >
        <img
          src="@/assets/img/xero-logo.png"
          style="width: 40px"
        />
        <span class="ml-3">
          Connect with Xero
        </span>
      </button>
    </div> -->
    <!-- <div v-if="upgradeError" class="alert alert-danger mt-3" role="alert">
        {{ upgradeError }}
    </div> -->
  </div>
</template>

<script>
import BusinessDetails from '../components/BusinessDetails.vue';

export default {
  name: "OrgSelector",
  components: {
    BusinessDetails
  },
  data() {
    return {
      showBusinessDetails: false
    }
  },
  computed: {
    connected_accounts() {
      return this.$store.getters.connected_accounts
    }

  },
  created() {
    // this.connected_orgs()
  },
  methods: {
    connectNewAccount(){
      this.showBusinessDetails = true;
      return;
    },
    // connected_orgs() {
      // getting this from api
        // api.get('/v2/@@session')
        // .then(({ data }) => {
        //  //console.log (data.data.accounts)
        //  this.orgList = data.data.accounts
        // })

      // old code...
      //return this.$store.getters.connected_orgs
    // },
    // goToXeroLogin() {
    //   window.dataLayer.push({
    //       event: 'app-action',
    //       app_action: 'connect-org',
    //       app_action_category: 'org-selector',
    //       app_action_label: 'xero',
    //   })
    //   location.assign("/login/xero?intent=supplypredict");
    // },
    async setSelectedOrg(orgId) {
      const url = new URL(window.location.href)

      this.upgradeError = null
      // if (org.payment_plan == 'minireport') {
      //   try {
      //     await api.post('integrations/authed-xero/' + org.account.account_id + '/@@paypredict-upgrade')
      //   } catch (err) {
      //     this.upgradeError = err
      //     return
      //   }
      // }
      window.console.log(orgId)
      this.$cookie.set("orgId", orgId, "30d")
      url.searchParams.set('orgId', orgId)
      // Note: setting window.location.href refreshes the page
      window.location.href = url.toString()
    },
  },
  mounted() {
      this.$nextTick(() => window.dataLayer.push({event: 'app-modal-view', app_modal: this.$options.name,}))
  },
};
</script>
<style type="text/css" scoped>

</style>
