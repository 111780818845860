<template>
  <div id="app">
    <div class="container-scroller">
      <!-- IF THERE IS A 500 ERROR SHOW THIS -->
      <div id="application-fault" v-if="application_fault || test.logout || password_reset">

        <div class="container-fluid page-body-wrapper full-page-wrapper">
          <div
            class="content-wrapper login-wrapper d-flex align-items-center auth px-0"
          >
            <div class="row w-100 mx-0">
              <div class="col-lg-4 mx-auto">
                <div
                  class="card text-left py-5 px-4 px-sm-5"
                >
                  <div class="brand-logo">
                    <img
                      src="https://evenly.com.au/storage/app/media/app/sp-logo-eq-red.png"
                      alt="Supplier IQ"
                    />
                  </div>
                  <PasswordReset v-if="password_reset" />
                  <div v-else>
                    <div class="pt-3 d-grid">
                      <button
                        v-if="application_fault"
                        class="btn btn-secondary"
                        @click="refreshPage"
                        style="height: 50px;"
                        id="btnRefreshOnAppFault"
                      >
                        <span class="ml-3">
                          Try Refreshing the Page
                        </span>
                      </button>
                      <!--
                        Login:
                        They are an accoutant or bookkeeper
                      -->
                      <Login
                        v-if="$store.state.signup_type === 'advisor'"
                      />
                      <!--
                        Login:
                        They are a normal business with or without a refer ID
                      -->
                      <ReferLogin v-else />
                  </div>
                  </div>
                </div>
                <div class="mt-5 bg-grey px-3 py-2">
                  <p class="text-muted mt-3 subtitle2">
                    By signing in you agree with our
                    <a href="https://evenly.com.au/terms" target="_blank"
                      >Terms and Conditions</a
                    >
                    and
                    <a
                      href="https://evenly.com.au/privacy"
                      target="_blank"
                      >Privacy Policy</a
                    >.
                  </p>
                  <p class="text-muted mt-3 subtitle2">
                    <strong>Problems or Questions?</strong> Drop us an
                    email at
                    <a href="mailto:hello@evenly.com.au"
                      >hello@evenly.com.au</a
                    >
                    and we'll get back to you straight away.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- content-wrapper ends -->
        </div>
      </div>
      <!-- IF THERE IS A 500 ERROR SHOW THIS -->

      <!-- No error, continue with the app -->
      <div v-else>
        <template>
          <!-- Load data - show the spash screen -->
          <Splash v-if="session_dirty" />
          <template v-else>
            <!--
              ##################################################################################
              Login:
            -->
            <div v-if="!logged_in && share_organisation_id == null">
              <div class="container-fluid page-body-wrapper full-page-wrapper">
                <div
                  class="content-wrapper login-wrapper d-flex align-items-center auth px-0"
                >
                  <div class="row w-100 mx-0">
                    <div class="col-lg-4 mx-auto">
                      <div
                        class="card text-left py-5 px-4 px-sm-5"
                      >
                        <div class="brand-logo">
                          <img
                            src="@/assets/images/sp-logo-eq-red.png"
                            alt="SupplyPredict"
                          />
                        </div>
                        <PasswordReset v-if="password_reset" />
                        <div v-else>
                          <div class="pt-3">
                            <!--
                              Login:
                              They are an accoutant or bookkeeper
                            -->
                            <Login
                              v-if="$store.state.signup_type === 'advisor'"
                            />
                            <!--
                              Login:
                              They are a normal business with or without a refer ID
                            -->
                            <ReferLogin v-else />
                        </div>
                        </div>
                      </div>
                      <div class="mt-5 bg-grey px-3 py-2">
                        <p class="text-muted mt-3 subtitle2">
                          By signing in you agree with our
                          <a href="https://evenly.com.au/terms" target="_blank"
                            >Terms and Conditions</a
                          >
                          and
                          <a
                            href="https://evenly.com.au/privacy"
                            target="_blank"
                            >Privacy Policy</a
                          >.
                        </p>
                        <p class="text-muted mt-3 subtitle2">
                          <strong>Problems or Questions?</strong> Drop us an
                          email at
                          <a href="mailto:hello@evenly.com.au"
                            >hello@evenly.com.au</a
                          >
                          and we'll get back to you straight away.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- content-wrapper ends -->
              </div>
            </div>

            <!-- They have logged in -->
            <div v-if="!fetchingSessionData">
              <!-- but there's no connected accounts on SP -->
              <template v-if="$store.getters.connected_accounts.length == 0">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                  <div class="content-wrapper login-wrapper d-flex align-items-center auth px-0">
                    <div class="row w-100 mx-0">
                      <div class="col-lg-4 mx-auto">
                        <div
                          class="card text-left py-5 px-4 px-sm-5"
                        >
                          <div class="brand-logo">
                            <img
                              src="@/assets/images/sp-logo-eq-red.png"
                              alt="SupplyPredict"
                            />
                          </div>
                          <BusinessDetails />

                          <div class="my-4">
                            <p class="mb-0"> <strong>Already have an account?</strong> <a href="#" @click="test.logout = true">Login</a></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <!-- If user hasn't selected an org then show the org selector -->
              <template v-else>
                <div v-if="!is_xero_org_in_url">
                  <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper login-wrapper-2 d-flex align-items-center auth px-0">
                      <div class="row w-100 mx-0">
                        <div class="col-lg-4 mx-auto">
                          <div class="card text-left py-5 px-4 px-sm-5">
                            <div class="card-body d-grid gap-2">
                              <div class="brand-logo">
                                <img
                                  src="@/assets/images/sp-logo-eq-red.png"
                                  alt="SupplyPredict"
                                />
                              </div>
                              <OrgSelector  />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- If the organisation hasn't loaded wait before showing the dashboard/other views -->
                <!-- <StandBy v-else-if="!org_loading_complete && share_organisation_id == null" /> -->
                <AppMain v-else>
                  <!-- HEADER if in app -->
                  <Header
                  v-if="share_organisation_id == null"
                  @CallPrintThis="printThis" />
                  <HeaderShare v-else
                    @CallPrintThis="printThis"/>
                  <div
                    class="container-fluid page-body-wrapper"
                    ref="Main">

                    <!-- / HEADER -->
                    <router-view  />
                  </div>
                  <!-- FOOTER -->

                  <Footer />
                  <!-- / FOOTER -->
                </AppMain>
              </template>
            </div>
          </template>
        </template>
      </div>
    </div>
    <!-- container-scroller ends -->
  </div>
</template>

<script>
import "bootstrap";
import Splash from "./views/Splash.vue"; // Loading data screen
import Login from "./views/Login.vue"; // Login for Accountants and Bookkeepers
import ReferLogin from "./views/ReferLogin.vue"; // Login for Business, checks for refer id.
import AppMain from "./components/AppMain.vue"; // The main app screen, holds router
import OrgSelector from "./views/OrgSelector.vue"; // The main app screen, holds router
// import StandBy from "./views/StandBy.vue";
import PasswordReset from "./views/PasswordReset.vue";
//import Steps from "./components/Steps.vue";

import BusinessDetails from './components/BusinessDetails';

import Header from "./components/layout/Header.vue";
import HeaderShare from "./components/layout/HeaderShare.vue";
import Footer from "./components/layout/Footer.vue";

import html2canvas from "html2canvas"

export default {
  components: {
    Splash,
    Login,
    ReferLogin,
    AppMain,
    //  StandBy,
    PasswordReset,
    OrgSelector,
    //  Steps,
    Header,
    HeaderShare,
    Footer,
    BusinessDetails,
  },

  data() {
    return {
      waiting_sync_failures: 0,
      waiting_sync_last_failure: null,
      settings: {
        referral_client_id: null,
      },
      test: {
        logout: false,
      },
      password_reset: false,
      fetchingSessionData: true,
    };
  },

  methods: {
    GoToLogin() {
      location.assign("/login/xero");
    },
    refreshPage() {
      location.reload();
    },
    async printThis() {
      const el = this.$refs.Main;
      const options = {
        type: "dataURL",
      };
      const printCanvas = await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute("download", "evenly-supplier-iq.png");
      link.setAttribute(
        "href",
        printCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream")
      );
      link.click();
    },
  },

  computed: {
    application_fault() {
      if (this.$store.state.application_fault) {
        window.console.log("ERROR: " + this.$store.state.application_fault);
      }
      return this.$store.state.application_fault;
    },

    session_dirty() {
      return this.$store.state.session_dirty;
    },
    share_organisation_id() {
      return this.$store.state.share_organisation.xero_authed_organisation_id;
    },

    logged_in() {
      return this.$store.getters.logged_in;
    },

    is_xero_org_in_url() {
      const params = new URLSearchParams(window.location.search)
      return params.get('orgId') != null
    },
    crisko_user_setup_incomplete() {
      return this.$store.getters.crisko_user_setup_incomplete;
    },
    // org_loading_complete() {
    //   return this.$store.getters.org_loading_complete;
    // },
    xero_is_connected() {
      return this.$store.getters.xero_is_connected;
    },
    finished_syncing() {
      return this.$store.getters.finished_syncing;
    },

    contacts_report_dirty() {
      return this.$store.state.contacts_report_dirty;
    },

    current_report_id() {
      return this.$store.state.authed_xero.current_report_id;
    },

    fullPath() {
      return this.$route.fullPath
    }
  },

  watch: {
    $route: {
      handler: (to, from) => {
        document.title = to.meta.title || " - Evenly SupplierIQ";
        if (from && to.fullPath !== from.fullPath) {
            window.dataLayer.push({
                event: 'app-page-view',
                app_page_path: to.fullPath,
                app_modal: '',
            })
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.fetchingSessionData = true;

      this.$store.dispatch("refresh_session").then(({ data }) => {
        if (data.crisko_user_info) {
          this.$store.dispatch("get_users_connected_accounts").finally(() => this.fetchingSessionData = false);
        }
      });
    });

    this.$nextTick(() => {
      // Regularly refresh session so know if user no longer authenticated
      window.setInterval(() => {
        //console.log("refresh session");
      }, 60 * 60 * 24 * 2 * 1000);
    });

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("orgId") != null && urlParams.get("token") != null) {
      this.$store.dispatch("set_share_organisation");
    } else if (urlParams.get("password_reset_token") != null && urlParams.get("email") != null){
      this.password_reset = true
    }

    window.dataLayer.push({
        event: 'app-page-view',
        app_page_path: '/',
        app_modal: '',
    })
  },
};
</script>

<style lang="scss">
</style>