export default {
  data() {
    return {
      otp: null,
      otp_sending: false,
      otp_error: null,
      otp_sent: false,
      countDownTimerForOTP: 0,
    }
  },
  methods: {
    startCountDown() {
      this.countDownTimerForOTP -=1;

      if (this.countDownTimerForOTP > 0) {
        setTimeout(() => {
          this.startCountDown()
        }, 1000)
      }
    },
    async send_email_otp() {
      if (this.countDownTimerForOTP > 0) {
        return
      }

      // Reset state if the button was pressed a second time
      this.otp_error = null;
      this.otp_sent = false;

      // Make async request
      this.otp_sending = true;
      let response = null;
      let error = null;
      try {
        const authorization = "Basic " + btoa(this.email + ":" + this.password);
        response = await api.post(
          '@@send-email-otp',
          {},
          { headers: { authorization } }
        );
      } catch(err) {
        error = err;
        if (error.response) {
          response = error.response;
        }
      }
      this.otp_sending = false;

      // Process result
      if (response !== null) {
        if (response.status === 200) {
          this.otp_sent = true;
          this.$toast.open({ message: "Please check the email address you entered for your verification code."})

          this.countDownTimerForOTP = 120;
          this.startCountDown();
        } else if (response.status === 403) {
          this.otp_error = "Invalid username or password"
        } else {
          this.otp_error = "Unexpected error";
        }
      } else {
        this.otp_error = error | "Unexpected error"
      }
    },
  }
}