<template>
  <div>
    <!-- Login form -->
    <div v-if="showLoginForm && !showForgotPassword">
      <p>
        <label>Email:</label>
        <input v-model="email" name="email" type="email" class="form-control"/>
      </p>
      <p class="error_msg" v-if="email_error">{{ email_error }}</p>
      <p>
        <label>Password:</label>
        <input v-model="password" name="password" type="password" class="form-control">
      </p>
      <p class="error_msg" v-if="password_error">
        {{ password_error }}
        <a v-if="show_signup_link" @click="switch_to_signup">HERE</a>
      </p>
      <p>
        <div class="row g-0 align-items-end">
          <label>Email Verification Code</label>
          <div class="col">
            <input v-model="otp" name="otp" type="text" class="form-control" :disabled="!otp_sent">
          </div>
          <button
            @click="send_email_otp"
            :loading="otp_sending"
            :disabled="otp_sending || (!email && !password) || countDownTimerForOTP > 0"
            class="btn btn-sm mx-2 py-2 px-0 col-7"
            :class="{
              'send-me-code' : countDownTimerForOTP == 0,
              'btn-gray' : countDownTimerForOTP > 0
            }"
            >
              {{ otp_sent ?  `Resend me a code ${(countDownTimerForOTP > 0 ? `(${countDownTimerForOTP})` : '')}` : 'Send me a verification code' }}
          </button>
        </div>
      </p>

      <!-- <p v-if="otp_sent" class="alert alert-success">
        <strong>Sent.</strong> Check your inbox.
      </p> -->

      <p class="error_msg" v-if="otp_error">
        {{ otp_error }}
      </p>
      <button
        class="btn btn-block mb-2"
        :class="{
          'btn-primary' : otp_sent,
          'btn-gray' : !otp_sent
        }"
        @click="login"
        :disabled="loading || !otp_sent"
      >
        <span class="ml-3">
          <strong>{{ buttonText }}</strong>
        </span>
      </button>
      <a href="#" @click="showForgotPasswordForm">
        <p style="padding-top: 12px;">Forgot Password</p>
      </a>
      <a href="#" @click="exit">
        <p>Use a different sign in method</p>
      </a>
    </div>
    <!-- Forgot Password For -->
    <div v-else-if="!showLoginForm && showForgotPassword">
      <ForgotPassword />
    </div>
  </div>
</template>
<script>
import api from "@/api.js";
import OTPMixins from "@/mixins/otp.js";
import ForgotPassword from "@/components/ForgotPassword.vue";

export default {
  name: "emailLogin",
  components: {
    ForgotPassword
  },
  mixins: [OTPMixins],
  data() {
    return {
      loading: false,
      email: "",
      email_error: null,
      password: "",
      password_error: null,
      show_signup_link: false,
      showForgotPassword: false,
      showLoginForm: true,
    };
  },
  computed: {
    buttonText (){
      if (this.loading) {
        return "Loading..."
      }
      return "Login"
    }
  },
  methods: {
    login() {
      // Reset error messages
      this.email_error = null
      this.password_error = null
      this.show_signup_link = false

      // Check email and password
      if (this.email == ""){
        this.email_error = "This field is required"
        return
      }
      if (this.password == ""){
        this.password_error = "This field is required"
        return
      }

      // Attempt to login
      this.loading = true
      window.dataLayer.push({
          event: 'app-action',
          app_action: 'login',
          app_action_category: 'login',
          app_action_label: 'password',
      })

      api.post(
        '@@login',
        {otp: this.otp},
        {headers: {
          'Authorization': "Basic " + btoa(this.email + ":" + this.password)
        }}
      )
      .then(resp => document.location = "/sp")
      .catch(() => {
        this.password_error = "Invalid email/password."
        this.loading = false
      })
    },
    showForgotPasswordForm() {
      this.showLoginForm = false
      this.showForgotPassword = true
    },
    exit() {
      this.$emit("exit")
    },
    switch_to_signup() {
      this.$emit("switch_to_signup")
    }
  },
};
</script>
<style type="text/css" scoped>
.error_msg {
  color: #000;
  background-color: #FFBABA;
  border: 1px solid;
  padding: 15px;
}
</style>
